import Vue from 'vue';
import Vuex from 'vuex';
Vue.use(Vuex)

import common from './common'; //公共库
import meetingInvite from './meetingInvite'; //会议邀请

const store = new Vuex.Store({
  modules: {
    common: common,
    meetingInvite: meetingInvite
  }
})
export default store;
