//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

	import httpStatusCode from './httpStatusCode.js';
	export default {
		model: {
			prop: 'visible',
			event: "dialogListener"
		},
		props: {
			visible: { //控制弹出、关闭
				type: Boolean,
				default: false
			},
			closeModal: { //是否点击遮罩关闭iError
				type: Boolean,
				default: true
			},
			width: {
				default: 80
			},
			title: { //提示
				type: String,
				default: '错误提示'
			},
			content: { //内容文本
				type: Object,
				default: function() {
					return {}
				}
			},
			isCenter: { //内容文本是否居中显示
				type: Boolean,
				default: false
			},
			hasLeft: { //是否有取消按钮
				type: Boolean,
				default: true
			},
			hasRight: { //是否有确认按钮
				type: Boolean,
				default: false
			},
			leftBtnTxt: { //取消按钮文本
				type: String,
				default: "客服中心"
			},
			rightBtnTxt: { //确定按钮文本
				type: String,
				default: "详情"
			}
		},
		watch: {
			visible() {
				this.isShow = this.visible;
			},
			isShow() {
				this.$emit("dialogListener", this.isShow);
			}
		},
		filters: {
			formatStatus(val) {
				return httpStatusCode[val];
			}
		},
		data() {
			return {
				isShow: false,
				errorList: [],
				zIndex: 30000
			}
		},
		created() {

		},
		methods: {
			// 打开组件
			show(options) {
				Object.assign(this.$props, options);
				this.errorList = [options.content];
				this.errorList.map(ele => {
					this.$set(ele, "isActive", false)
				})
				this.isShow = true;
			},
			// 更新组件入口
			update(options) {
				this.errorList = [...this.errorList, options.content];
				this.errorList.map(ele => {
					this.$set(ele, "isActive", false)
				})
			},
			//确定
			confirm() {
				this.$emit("confirm");
				this.isShow = false;
			},
			// 取消
			cancel() {
				this.isShow = false;
				this.$emit("cancel");
			},
			// 是否点击遮罩关闭errorBox
			closeOnClick() {
				this.closeModal && this.cancel();
			},
			showDetail(item, index) {
				this.errorList.map((ele, i) => {
					if(index != i || item.isActive) {
						document.getElementsByClassName('self_icon' + i)[0].style.transition = "transform 0.2s ease-out";
						document.getElementsByClassName('self_icon' + i)[0].style.transform = "rotateZ(" + 0 + "deg)";
						ele.isActive = false;
					} else if(!item.isActive) {
						let _selfIcon = document.getElementsByClassName('self_icon' + index)[0];
						let _rotateZ = item.isActive ? '0' : '90';
						_selfIcon.style.transition = "transform 0.2s ease-out";
						_selfIcon.style.transform = "rotateZ(" + _rotateZ + "deg)";
						item.isActive = true;
					}
				})
			}
		}
	}
