//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

	/*注意：contentTxt同<slot name="content"></slot>是互斥存在的
		因为该组件既满足于提示文本框，亦可满足带有自定义表单输入等场景形式
	**/
	export default {
		model: {
			prop: 'visible',
			event: "dialogListener"
		},
		props: {
			visible: { //控制弹出、关闭
				type: Boolean,
				default: false
			},
			width: {
				default: 70
			},
			title: { //提示
				type: String,
				default: '再次确认'
			},
			contentTxt: { //内容文本
				type: String,
				default: ''
			},
			cancelTxt: { //取消按钮文本
				type: String,
				default: "取消"
			},
			confirmTxt: { //确定按钮文本
				type: String,
				default: "确定"
			},
			isCenter: { //内容文本是否居中显示
				type: Boolean,
				default: true
			},
			closeModal: { //是否点击遮罩关闭iMessage
				type: Boolean,
				default: true
			},
			hasTemplate: { //是否使用自定模板插槽，此功能与contentTxt属性互斥！有他没我，
				type: Boolean,
				default: false
			},
			hasInput: { //是否使用带有textArea模式，此功能与hasTemplate && contentTxt互斥！
				type: Boolean,
				default: false
			},
			inputType: {
				type: String,
				default: "textarea"
			},
			label: {
				type: String,
				default: ""
			},
			value: { //带有输入框模式输入的文本
				default: ""
			},
			hasLeft: { //是否有取消按钮
				type: Boolean,
				default: true
			},
			hasRight: { //是否有确认按钮
				type: Boolean,
				default: true
			},
			hasInterval: { //是否有定时器功能
				type: Boolean,
				default: false
			},
			intervalTitle: { //定时器提示
				type: String,
				default: '后将自动退出'
			},
			timer: {
				type: Number,
				default: 5
			}
		},
		watch: {
			visible() {
				this.isShow = this.visible;
			},
			isShow() {
				this.$emit("dialogListener", this.isShow);
			},
			value() {
				if(this.value.length > 100) {
					this.$iToast("内容过长");
					this.value = this.value.substring(0, 100)
				}
			}
		},
		data() {
			return {
				isShow: false,
				zIndex: 30000
			}
		},
		created() {
			this.isShow = this.visible;
		},
		methods: {
			//plugin方式打开
			show(options) {
				Object.assign(this.$props, options);
				this.isShow = true;
				this.init();
				this.autoFocus();
			},
			// 带有输入框，自动获取焦点
			autoFocus() {
				if(this.hasInput) {
					this.$nextTick(() => {
						this.$refs.inputBox.focus()
					})
				}
			},
			init() {
				//计时器功能
				if(this.hasInterval) {
					this.title = `${this.timer}s${this.intervalTitle}`;
					var intervalId = setInterval(() => {
						this.timer--;
						this.title = `${this.timer}s${this.intervalTitle}`;
						if(this.timer == 0) {
							window.clearInterval(intervalId);
							this.confirm();
						}
					}, 1000)
				}
			},
			//确定
			confirm() {
				if(this.hasInput && !this.value) {
					this.$emit("confirm", this.value || "verify");
					return false;
				}
				this.$emit("confirm", this.hasInput ? this.value : '');
				this.isShow = false;
			},
			//取消
			cancel() {
				this.isShow = false;
				this.$emit("cancel");
			},
			//是否点击遮罩关闭messageBox
			closeOnClick() {
				this.closeModal && this.cancel();
			}
		}
	}
