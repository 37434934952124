import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import { iStorage, iRem, fixIOSInput, fixAndroidInput, iDelay, formatDate } from './core';
//iRecordHttpLog.install();
iStorage.install();
// uris.install()
import iJsBridge from 'smart-javascript-bridge';
import MintUI from 'mint-ui';
import 'mint-ui/lib/style.css';
import 'vant/lib/index.css';
/**初始化css */
import '@/assets/css/initStyle.scss';
/**公共皮肤 */
import '@/assets/css/theme.scss';

import "smart-filer/dist/smart-filer.min.css";

//全局监听安卓返回
import '@/core/androidBackBridge';

import './registerServiceWorker';
//注入自定义指令
import '@/core/directives';
//注入过滤器
import '@/core/filter/install';
// 引入cookie
import * as tinyCookie from 'tiny-cookie';
//全局css
import '@/assets/css/public.css';
import CacheData from "@/controllers/cache-data.js"
import AppInitialization from "@/controllers/app-initialization";

iRem.install(document)

Vue.use(MintUI);

//vux时间控件
import DatetimePlugin from 'vux/src/plugins/datetime';
Vue.use(DatetimePlugin)

//iMessage
import iMessage from '@/icomponents/iMessage/plugin.js';
//iToast
import iToast from '@/icomponents/iToast/plugin.js';
//iPopup
import iPopup from "@/icomponents/iPopup/plugin.js";

Vue.use(fixIOSInput);
Vue.use(fixAndroidInput);

Vue.prototype.iStorage = iStorage;
Vue.prototype.$eventHub = Vue.prototype.$eventHub || new Vue();
Vue.prototype.$cookies = tinyCookie;
Vue.prototype.iJsBridge = new iJsBridge({ alert: iToast });
Vue.prototype.$iMessage = iMessage;
Vue.prototype.$iToast = iToast;
Vue.prototype.$iPopup = iPopup;
Vue.prototype.$iDelay = iDelay;
Vue.prototype.$formatDate = formatDate;

Vue.config.productionTip = false

Promise.resolve().then(() => {
  return CacheData.install()
}).then(() => {
  return AppInitialization.install()
}).then(() => {
  new Vue({
    router,
    store,
    render: h => h(App)//,
    // data: {
    //   $eventHub: new Vue()
    // }
  }).$mount('#app')
})
