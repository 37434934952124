import { request } from "./request";

// var tenantCode = iStorage.get("tenant");

export const miceService = {
	// 数据持久化-保存
	saveCacheData: (params) => {
		let data = {
			...params,
			Prefix: "jwts_"
		}
		return request.post('/api/statestore/redis/create', data);
	},
	/**
	 * 公共post
	 */
	common: (url, params, conf) => {
		return request.post(url, params || {}, conf || {});
	},
	/**
	 * 获取会议接口
	 */
	bizConfQuery: (params) => {
		return request.get("/api/resource/video/BizConf/Query", {params:params});
	},
	/**
	 * 获取会议室类型接口
	 */
	mettingPriceQuery: (params) => {
		return request.get("/api/resource/video/MettingPrice/Query", {params:params});
	},
	/**
	 * 添加会议接口
	 */
	mettingadd: (params) => {
		return request.post("/api/resource/video/Metting/Add", params);
	},
	/**
	 * 删除会议接口
	 */
	mettingDelete: (params) => {
		return request.post("/api/resource/video/Metting/Delete", params);
	},
	/**
	 *  取消预定的会议接口
	 */
	CancelMetting: (params) => {
		return request.post("/api/resource/video/BizConf/CancelMetting", params);
	},
	/**
	 * 取消会议有损失
	 */
	BizConfLossMetting: (params) => {
		return request.post("/api/resource/video/BizConf/LossMetting", params);
	},
	/**
	 * 修改会议接口
	 */
	mettingUpdate: (params) => {
		return request.post("/api/resource/video/Metting/Update", params);
	},
	/**
	 * 修改预约会议接口
	 */
	UpdateBookingMetting: (params) => {
		return request.post("/api/resource/video/BizConf/UpdateBookingMetting", params);
	},
	/**
	 * 修改进行中会议接口
	 */
	UpdateConveneMetting: (params) => {
		return request.post("/api/resource/video/BizConf/UpdateConveneMetting", params);
	},
	/**
	 * 预定会议接口
	 */
	bizConfBookMetting: (params) => {
		return request.post("/api/resource/video/BizConf/BookMetting", params);
	},
	/**
	 * 添加邀请人
	 */
	invitationAdd: (params) => {
		return request.post("/api/resource/video/Invitation/Add", params);
	},
	/**
	 * 删除邀请人
	 */
	invitationDelete: (params) => {
		return request.post("/api/resource/video/Invitation/Delete", params);
	},
	/**
	 * 修改邀请人
	 */
	invitationUpdate: (params) => {
		return request.post("/api/resource/video/Invitation/Update", params);
	},
	/**
	 * 查询邀请人
	 */
	invitationQuery: (params) => {
		return request.get("/api/resource/video/Invitation/Query", {params:params});
	},
	/**
	 * 发送邮件
	 */
	invitationSendMail: (params) => {
		return request.post("/api/resource/video/Invitation/SendMail", params);
	},
	/**
	 * 获取邀请信息
	 */
	GetinvitationInfo: (params) => {
		return request.get("/api/resource/video/Invitation/GetinvitationInfo", {params: params});
	},
	/**
	 * 获取地址
	 */
	QueryUrl: (params) => {
		return request.post("/api/resource/video/Invitation/QueryUrl", params);
	},
	/**
	 * 开始会议
	 */
	StartMetting: (params) => {
		return request.post("/api/resource/video/BizConf/StartMetting", params);
	},
	/**
	 * 结束会议
	 */
	EndMetting: (params) => {
		return request.post("/api/resource/video/BizConf/EndMetting", params);
	},
	/**
	 * 获取备注信息
	 */
	getTips: (params) => {
		return request.get("/api/resource/video/Remark/Query", params);
	},
	/**
	 * 结束超时会议接口
	 */
	JoberEndMetting: (params) => {
		return request.get("/api/resource/video/Jober/EndMetting", params);
	},
	/**
	 * 是否能添加需求
	 */
	CheckCanNewItem: (params) => {
		return request.get("/api/resource/video/Metting/CheckCanNewItem", {params:params});
	},
	// 查询常用联系人/ HCP库联系人
	QueryContants: (params) => {
		return request.post('api/resource/video/Contact/Query', params)
	},
	// 新增联系人
	ContactAdd: (params) => {
		return request.post('api/resource/video/Contact/Add', params)
	},
	// 编辑参会人
	ContactUpdate: (params) => {
		return request.post('api/resource/video/Contact/Update', params)
	},
	// 删除参会人
	ContactDelete: (params) => {
		return request.post('api/resource/video/Contact/Delete', params)
	},
	// 导入常用联系人
	ContactImport: (params) => {
		return request.post('api/resource/video/Contact/Import', params)
	},
	//同步邀请人
	InvitationSync: (params) => {
		return request.post('api/resource/video/Invitation/Sync', params)
	},
	// 删除邀请人
	InvitationDelete: (params) => {
		return request.post('api/resource/video/Invitation/Delete', params)
	},
	//更新邀请人
	InvitationUpdate: (params) => {
		return request.post('api/resource/video/Invitation/Update', params)
	},
	//发送邀请邮件
	SendEmailInvitation: (params) => {
		return request.post('api/resource/video/Invitation/SendEmailInvitation', params)
	},
	//发送邀请短信
	SendSMSInvitation: (params) => {
		return request.post('api/resource/video/Invitation/SendSMSInvitation', params)
	},
	//修改参会人邀请状态
	UpdateInvitationStatus: (params) => {
		return request.post('api/resource/video/Invitation/UpdateInvitationStatus', params)
    },
    // 查询医院
    QueryHospital(params) {
        return request.post(`/foundation/dict/IHospitalService/QueryHospital`, params);
    },
    
    // TPM getSettings 接口
    /** TPM querysettings接口
     * TPM参数示例:  
     *  var params = {
            collection: "cfg-ievent-eventEditor",
			useCaching: true,
			cachingKey: "",
			filter: {
				tenantCode: 'ipsen'
			}
        };
     */
    querysettings: (params) => {
        return request.post('/api/tpm/cfg/querysettings', params);
    },
	getsettings: (params) => {
        return request.post('/api/tpm/cfg/getsettings', params);
    },
	GetQueryData: (params) => {
        return request.post('/xchief/api/xquery/GetQueryData', params);
    },
	QuerySettings: (params) => {
        return request.post('/xchief/api/xconfig/QuerySettings', params);
    },
	GetQueryObject: (params) => {
        return request.post('/xchief/api/xquery/GetQueryObject', params);
    },
	SaveItem: (params) => {
        return request.post('/api/tenants/roche/VMeeting/SaveItem', params);
    },
    /** 获取channelId和SDK KEY */
    getKey: (params) => {
        return request.get('/api/resource/video/BizConf/GetSettings', {params: params});
    }
}
