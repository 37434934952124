const publicStore = {
    state: {
        catchPages: [], //路由缓存组件,值为组件name
        includes:[], // 需要 缓存的路由
        isMoreClick: false, // 提交按钮时 设为false 避免重复提交,多次http请求
    },
    mutations: {
        // 设置 publicStore 统一方法
        setPublicValue(state, value) {
            for (var key in value) {
                state[key] = value[key];
            }
        },
    },
    getters: {
        includes: state => state.includes,
        isMoreClick: state => state.isMoreClick,
    }
}

export default publicStore;
