const meetingInvite ={
    namespaced: true,
    state: {
        confId: "",
        selectedPerson: [], // 选中的联系人
    },
    mutations: {
        // 设置 publicStore 统一方法
        setPublicValue(state, value) {
            for (var key in value) {
                state[key] = value[key];
            }
        },
    },
    getters: {
        selectedPerson: state => state.selectedPerson,
    }
}

export default meetingInvite
