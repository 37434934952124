import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
	// 需求列表模块
	{
		path: '/',
		name: 'requirementsList', // 需求列表
		component: () => import('@/views/requirementsList.vue')
	},
	// 添加需求列表模块
	{
		path: '/addDemand',
		name: 'addDemand', // 需求列表
		component: () => import('@/views/addDemand.vue')
	},
    // a1添加需求
    {
        path: '/addR1Demand',
        name: 'addR1Demand',
        component: () => import('@/views/addR1Demand.vue')
    },
	// 需求详情模块
	{
		path: '/demandDetails',
		name: 'demandDetails', // 需求列表
		component: () => import('@/views/demandDetails.vue')
	},
	// 添加会议人员模块
	{
		path: '/addPerson',
		name: 'addPerson', // 需求列表
		component: () => import('@/views/addPerson.vue')
	},
	// 会议邀请
	{
		path: '/invite',
		name: 'invite',
		component: () => import('@/views/Invite.vue')
	},
	// 录入人员信息
	{
		path: '/editPeopleInfo',
		name: 'editPeopleInfo',
		component: () => import('@/views/EditPeopleInfo.vue')
	},
	// 已邀请人员信息
	{
		path: '/invitedPeople',
		name: 'invitedPeople',
		component: () => import('@/components/personInfo/invitedPeople.vue')
	},
	{ // 已选择参会人列表
		path: '/inviteParticipants',
		name: 'inviteParticipants',
		component: () => import('@/components/personInfo/inviteParticipants.vue')
	},
	{ // 参会人列表
		path: '/inviteParticipantList',
		name: 'inviteParticipantList',
		component: () => import('@/components/personInfo/inviteParticipantList.vue')
	},
	{ // 新版邀约
		path: '/newInvite',
		name: 'newInvite',
		component: () => import('@/views/attender/index.vue'),
//		redirect: '/newInvite/meetingInfo',
		children: [{
			path: '/',
			name: 'meetingInfo',
			component: () => import('@/views/attender/meetingInfo.vue')
		}, {
			path: '/joinMeeting',
			name: 'joinMeeting',
			component: () => import('@/views/attender/joinMeeting.vue')
		}]
	}
]

const router = new VueRouter({
	mode: 'history',
	// base: process.env.BASE_URL,
	routes
})

export default router
