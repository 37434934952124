//
//
//
//
//
//
//
//

	export default {
		props: {
			//内容文本
			message: {
				type: String,
				default: ''
			},
			//显示时长
			time: {
				type: Number,
				default: 2000
			},
			//消息类型
			type: {
				type: String,
				default: 'default'
			},
			//最大屏幕宽度比
			maxWidth: {
				type: Number,
				default: 80
			}
		},
		computed: {
			toastStyle() {
				return {
					'z-index': this.zIndex + 10,
					top: `${this.top}px`,
					'max-width': `${this.maxWidth}%`
				}
			},
			theme() {
				const themeMap = new Map([
					['success', '#67c23a'],
					['info', '#909399'],
					['warning', '#e6a23c'],
					['danger', '#f56c6c'],
					['default', '#000000']
				]);
				//防止传入无效的type
				return themeMap.get(this.type) || themeMap.get('default');
			}
		},
		data() {
			return {
				isShow: false,
				zIndex: 40000,
				top: 200
			}
		},
		methods: {
			//plugin方式打开
			show(options) {
				Object.assign(this.$props, options);
				this.isShow = true;
				setTimeout(() => {
					this.close();
				}, this.time)
			},
			close() {
				this.isShow = false;
				this.$emit('close')
			}
		}
	}
