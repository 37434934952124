import { smartHttp } from 'smart-core-util'
import iToast from '@/icomponents/iToast/plugin.js';
import { indicator } from 'smart-indicator';
const envGatewayUrl = process.env.VUE_APP_GATEWAY_URL;

export const request = new smartHttp({
	baseURL: envGatewayUrl,
	alert: iToast,
	block: indicator.open, //开启loading
	unblock: indicator.close, //关闭loading
}).api;
