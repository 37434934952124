import { smartHttp } from "smart-core-util";
import {iStorage} from '../core'
import { setCookie , getCookie, removeCookie} from 'tiny-cookie'
import {Toast} from "mint-ui";
import {indicator} from "smart-indicator";
const request = new smartHttp({
    baseURL: process.env.VUE_APP_GATEWAY_URL,
  alert: Toast,
  block: indicator.open, //开启loading
  unblock: indicator.close, //关闭loading
  handle401: function() {}
}).api;
export default class CacheData {
    static install() {
        let url = window.location.search.substring(1);
        let urlParamsObj = {};
        if(url){
            let urlParams = url.split('&');
            urlParams.forEach(item => {
                let temp = item.split('=');
                urlParamsObj[temp[0]] = temp[1];
            })
        }
        if (urlParamsObj.sessionId) {
            setCookie("type", 'tenant');
            removeCookie('eventData')
        }
        return new Promise(function (resolve) {
            if (urlParamsObj && urlParamsObj.sessionId) {
                let params = {
                    "sessionId": urlParamsObj.sessionId || ""
                }
                request.post("/api/statestore/redis/query", params).then(response => {
                if(response && response.success) {
                    removeCookie('eventData')
                    iStorage.remove('venueList')
                    iStorage.remove('eventData')
                  CacheData.setData(response.content)
                }
                    resolve(response)
              }).catch(error => {
                resolve(error)
              });
            }else {
                resolve()
            }
        })
    }
    // 设置缓存数据
    static setData(d) {
        let objKeyList = Object.keys(d);
        objKeyList.map(item => {
            iStorage.set(item, d[item]);
        });
        !getCookie("token")&&CacheData.setCooikes(d)
        if(d.auth) {
            setCookie("token", d.auth.token || d.auth.access_token);
            setCookie("tenant", d.auth.tenantCode || d.auth.tenant_code);
            setCookie("tenant_id", d.auth.tenantId || d.auth.tenant_id);
            setCookie("container", d.auth.tenantId);
        }
        if(d.data) {
            let objKeyList = Object.keys(d.data);
            objKeyList.map(item => {
                iStorage.set(item, d.data[item]);
            });
            iStorage.set("proposalId", d.data.eventData.proposalId);
        }
    }
    static setCooikes(response) {
        let setting = {
            token: "token",
            userId: "userId",
            tenant_code: "tenant_code",
            tenant: "tenant_code",
            tenant_id: "tenant_id",
            role_codes: "role_codes",
            directory_id: "directory_id",
            supplierId: "supplierId",
            container:"container",
            platform:"platform",
        };
        // 获取过期时间
        let expiresTimes = "31104000s";
        if (response.container == "h5") {
            expiresTimes = "Session";
        }
        let config = { expires: expiresTimes, domain: document.domain };
        CacheData.appendCookie(response, setting, config);
    }
    // 添加到 cookie(注意：因为涉及多个站点共享cookie问题，所以需要设置主域名相同)
    static appendCookie(data, setting, config) {
        setCookie('token', data.access_token||data.token, config);
        for (var i in setting) {
            i!='token'&&setCookie(i, data[setting[i]], config);
        }
    }
}
