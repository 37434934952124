//
//
//
//
//
//
//

  import { mapGetters } from "vuex";
  export default {
    name: 'App',
    data (){
      return {

      }
    },
    computed:{
      ...mapGetters([
        "includes",
      ]),
    },
    created() {
      this.getTenant();
      this.editLoadElementZIndex();
    },
    mounted() {
      this.iStorage.set('_screen_height', document.documentElement.clientHeight || window.innerHeight);
    },
    methods: {
      getTenant () {
        //统一处理租户
        const tenantMap = new Map([
          ["msd", "msd"],
          ["merck", "merck"],
          ["merckweb", "merck"],
          ["roche", "roche"],
          ["rocheappstore", "roche"],
          ["roche-store", "roche"],
          ["rocheweb", "roche"],
          ["dsm", "dsm"],
          ["pfizer", "pfizer"],
          ["allergan", "allergan"],
          ["ipsen", "ipsen"]
        ]);
        this.iStorage.set("tenant", tenantMap.get(this.$cookies.getCookie('tenant')));
        this.iStorage.set("proposalId", this.$route.query.proposalId);
        this.getTheme();
      },
      //设置主题
      getTheme() {
        //根据租户更换皮肤颜色
        const tenatCookie = this.iStorage.get("tenant");
        let tenant = tenatCookie && tenatCookie.replace(/\s/g, "") || "roche";
        window.document.documentElement.setAttribute("data-theme", tenant);
      },
      //修改加载层级【默认的loading圈圈的层级不足，导致一些popup页面的加载效果没有呈现】
      editLoadElementZIndex() {
        // setTimeout(() => {
        //   const loadBox = document.getElementsByClassName('loadingOut')[0];
        //   const shadeBox = loadBox && loadBox.parentNode.children[1];
        //   loadBox && (loadBox.style.zIndex = '50000');
        //   shadeBox && (shadeBox.style.zIndex = '51000');
        // }, 1000)
      }
    }
  }
